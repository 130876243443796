import React from "react"
import { Container } from "@styles/utilityClasses"

const PrivacyPolicy = () => {
  return (
    <Container>
      <h1>Privacy Policy</h1>
      <p>Last updated: May 14, 2024</p>
      <h2>Introduction</h2>
      <p>
        Zenith Research ("we," "our," "us") is committed to protecting your
        privacy. This Privacy Policy explains how we collect, use, disclose, and
        safeguard your information when you visit our website{" "}
        <a href="https://zenith-research.ca">zenith-research.ca</a> and use our
        services. Please read this privacy policy carefully. If you do not agree
        with the terms of this privacy policy, please do not access the site.
      </p>
      <h2>Information We Collect</h2>
      <h3>Personal Data</h3>
      <p>
        We may collect personally identifiable information (PII), such as your
        name, email address, and telephone number, when you voluntarily provide
        it to us.
      </p>
      <h3>Usage Data</h3>
      <p>
        We may also collect information that your browser sends whenever you
        visit our website ("Usage Data"). This Usage Data may include
        information such as your computer's Internet Protocol (IP) address,
        browser type, browser version, the pages of our site that you visit, the
        time and date of your visit, the time spent on those pages, and other
        diagnostic data.
      </p>
      <h3>Cookies and Tracking Technologies</h3>
      <p>
        We use cookies and similar tracking technologies to track the activity
        on our website and hold certain information. Cookies are files with a
        small amount of data which may include an anonymous unique identifier.
        You can instruct your browser to refuse all cookies or to indicate when
        a cookie is being sent.
      </p>
      <h2>How We Use Your Information</h2>
      <p>We use the information we collect in the following ways:</p>
      <ul>
        <li>To provide, operate, and maintain our website</li>
        <li>To improve, personalize, and expand our website</li>
        <li>To understand and analyze how you use our website</li>
        <li>To develop new products, services, features, and functionality</li>
        <li>
          To communicate with you, either directly or through one of our
          partners, including for customer service, to provide you with updates
          and other information relating to the website, and for marketing and
          promotional purposes
        </li>
        <li>To process your transactions and manage your orders</li>
        <li>To find and prevent fraud</li>
      </ul>
      <h2>Disclosure of Your Information</h2>
      <p>
        We may share information we have collected about you in certain
        situations. Your information may be disclosed as follows:
      </p>
      <ul>
        <li>
          <strong>By Law or to Protect Rights:</strong> If we believe the
          release of information about you is necessary to respond to legal
          process, to investigate or remedy potential violations of our
          policies, or to protect the rights, property, and safety of others, we
          may share your information as permitted or required by any applicable
          law, rule, or regulation.
        </li>
        <li>
          <strong>Business Transfers:</strong> We may share or transfer your
          information in connection with, or during negotiations of, any merger,
          sale of company assets, financing, or acquisition of all or a portion
          of our business to another company.
        </li>
      </ul>
      <h2>Security of Your Information</h2>
      <p>
        We use administrative, technical, and physical security measures to help
        protect your personal information. While we have taken reasonable steps
        to secure the personal information you provide to us, please be aware
        that despite our efforts, no security measures are perfect or
        impenetrable, and no method of data transmission can be guaranteed
        against any interception or other type of misuse.
      </p>
      <h2>Your Data Protection Rights</h2>
      <p>
        Depending on your location, you may have the following rights regarding
        your personal information:
      </p>
      <ul>
        <li>
          <strong>The right to access:</strong> You have the right to request
          copies of your personal data.
        </li>
        <li>
          <strong>The right to rectification:</strong> You have the right to
          request that we correct any information you believe is inaccurate or
          complete information you believe is incomplete.
        </li>
        <li>
          <strong>The right to erasure:</strong> You have the right to request
          that we erase your personal data, under certain conditions.
        </li>
        <li>
          <strong>The right to restrict processing:</strong> You have the right
          to request that we restrict the processing of your personal data,
          under certain conditions.
        </li>
        <li>
          <strong>The right to object to processing:</strong> You have the right
          to object to our processing of your personal data, under certain
          conditions.
        </li>
        <li>
          <strong>The right to data portability:</strong> You have the right to
          request that we transfer the data that we have collected to another
          organization, or directly to you, under certain conditions.
        </li>
      </ul>
      <p>
        If you make a request, we have one month to respond to you. If you would
        like to exercise any of these rights, please contact us at our contact
        information below.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy, please
        contact us at:
      </p>
      <p>
        Zenith Research
        <br />
        Email:{" "}
        <a href="mailto:banana@zenith-research.ca">banana@zenith-research.ca</a>
        <br />
        Phone: 236-558-2381
        <br />
        Address: Vancouver, BC, Canada
      </p>
    </Container>
  )
}

export default PrivacyPolicy
